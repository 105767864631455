/* eslint no-underscore-dangle: 0 */
import {css} from '@emotion/react'
import {PageProps} from 'gatsby'
import {keyBy} from 'lodash'
import React, {useContext, useMemo} from 'react'
import {MenuStore, RedocStandalone, ScrollService} from 'redoc'

import {NavEntry} from '../components/nav/types'
import {articleMaxWidth, isSSR} from '../constants'
import HeaderHeightContext from '../providers/HeaderHeightContext'
import NavigationProvider from '../providers/NavigationProvider'
import VersionProvider from '../providers/VersionProvider'
import styles, {colors} from '../styles'
import {BaseVersionedPageContext, PageEntry} from '../types'

import PageWithNav from './PageWithNav'

// https://github.com/Redocly/redoc/issues/447#issuecomment-556124493
// don't allow redoc to update url hash or scroll itself to prevent jumpy scroll behavior
MenuStore.prototype.subscribe = function subscribeOverride() {
  /* @ts-expect-error: ts(2341) */
  this._unsubscribe = () => {}
  /* @ts-expect-error: ts(2341) */
  this._hashUnsubscribe = () => {}
}
ScrollService.prototype.scrollIntoView = () => {}

const themeOverride = {
  colors: {
    primary: {
      main: colors.kenshoBlue,
    },
  },
  breakpoints: {
    // force single column layout
    small: '0px',
    medium: '999999px',
    large: '999999px',
  },
  sidebar: {
    // set to 1px instead of 0 so that right dark panel positions correctly
    width: '1px',
  },
  typography: {
    fontFamily: styles.text.fontFamily,
    fontSize: '16px',
    code: {
      color: styles.code.colors.plain.color,
      backgroundColor: styles.code.colors.plain.backgroundColor,
      fontFamily: 'monospace',
    },
    headings: {
      fontFamily: styles.text.fontFamily,
    },
  },
  rightPanel: {
    backgroundColor: 'transparent',
    textColor: styles.code.colors.plain.color,
  },
  codeBlock: {
    backgroundColor: styles.code.colors.plain.backgroundColor,
  },
}
// NOTE: this is pulled out into a constant rather than passing as an inline prop because otherwise
// redoc will try to recreate the full tree and lose the state of expanded dropdowns/etc. on re-render
const redocOptions = {
  nativeScrollbars: true,
  theme: themeOverride,
  expandResponses: 'all',
  hideLoading: true,
  hideSingleRequestSampleTab: true,
  menuToggle: true,
  pathInMiddlePanel: true,
}

const padding = 24

const codeBlockCss = css`
  background: ${styles.code.colors.plain.backgroundColor};
  color: ${styles.code.colors.plain.color};
  text-shadow: none;
  border: none;

  .token {
    color: ${styles.code.colors.plain.color};

    &.string,
    &.attr-value {
      color: ${styles.code.colors.string};
    }
    &.function {
      color: ${styles.code.colors.function};
    }
    &.parameter {
      colors: ${styles.code.colors.functionVariable};
    }
    &.punctuation,
    &.operator {
      color: ${styles.code.colors.operator};
    }
    &.keyword,
    &.tag,
    &.selector {
      color: ${styles.code.colors.tag};
    }
    &.number,
    &.entity,
    &.url,
    &.symbol,
    &.boolean,
    &.variable,
    &.constant,
    &.property,
    &.regex {
      color: ${styles.code.colors.symbol};
    }
    &.comment,
    &.prolog,
    &.doctype,
    &.cdata {
      color: ${styles.code.colors.comment};
    }
  }
`

const redocCss = css`
  .redoc-wrap {
    .api-content .api-info {
      padding-top: 0;
    }

    .http-verb {
      border-radius: 3px;
      font-weight: bold;
    }

    .react-tabs__tab {
      background: #e6e6e6;
      border-color: transparent;

      &.react-tabs__tab--selected {
        background: #d9d9d9;
      }
    }

    .react-tabs__tab-list {
      .tab-success {
        color: #000;
        background: #e6e6e6;
        border: none;
      }

      .tab-error {
        border: none;
        color: #990000;
      }

      .react-tabs__tab--selected {
        background: #d9d9d9;
      }
    }

    .redoc-json,
    .redoc-xml {
      ${codeBlockCss}
    }

    .redoc-json code {
      background-color: transparent;
    }

    .react-tabs__tab-panel {
      pre {
        ${codeBlockCss}
      }

      .redoc-json code {
        background-color: transparent;
      }

      &.react-tabs__tab-panel--selected {
        margin-bottom: 20px;
      }
    }

    pre > code {
      ${codeBlockCss}
    }
  }
`

const articleCss = css`
  padding: ${padding}px;
  max-width: ${articleMaxWidth}px;
  position: relative;

  ${redocCss}
`

// mask hidden search border because we have to set to 1px wide to preserve layout calc's
const borderOverlayCss = css`
  background: white;
  width: 1px;
  height: 100%;
  position: absolute;
  left: ${padding}px;
  z-index: 1;
`

interface RouteSpec {
  summary: string
  tags?: string[]
  description?: string
  operationId: string
}

interface Tag extends NavEntry {
  name: string
  'x-displayName': string
}

interface RedocPageContext extends BaseVersionedPageContext {
  spec: {
    paths: {
      [path: string]: {
        [requestType: string]: RouteSpec
      }
    }
    tags?: Tag[]
  } // the schema is defined dynamically by the API, so this type is not comprehensive
  pageConfig: PageEntry
}

export default function Redoc(props: PageProps<never, RedocPageContext>): JSX.Element {
  const {pageContext} = props
  const {spec, navList, version, versions, pageConfig} = pageContext
  const {fullHeaderHeight} = useContext(HeaderHeightContext)

  // map the OpenApi spec to our tableOfContents format
  const derivedTableOfContents = useMemo(() => {
    if (!spec.tags) return null

    const tags: RedocPageContext['spec']['tags'] = spec.tags.map((tag) => ({
      ...tag,
      title: tag['x-displayName'] || tag.name,
      items: [],
      url: `#tag/${tag.name}`,
    }))

    const root = keyBy(tags, ({name}) => name)

    Object.entries(spec.paths).forEach(([, requestTypes]) => {
      Object.entries(requestTypes).forEach(([type, route]) => {
        if (!route.tags) return

        // group under first tag
        root[route.tags[0]].items?.push({
          title: `(${type}) ${route.summary}`,
          url: `#operation/${route.operationId}`,
        })
      })
    })

    return Object.values(root)
  }, [spec.paths, spec.tags])

  return (
    <VersionProvider versions={versions} version={version}>
      <NavigationProvider
        navList={navList}
        {...(derivedTableOfContents && {tableOfContents: derivedTableOfContents})}
      >
        <PageWithNav {...props} pageConfig={pageConfig}>
          <article css={articleCss}>
            <div css={borderOverlayCss} />
            <RedocStandalone
              spec={spec}
              options={redocOptions}
              onLoaded={() => {
                if (!isSSR && window.location.hash) {
                  // do browser hash scroll on mount
                  const scrollToEle = document.getElementById(
                    window.location.hash.replace(/^#/, '')
                  )
                  if (scrollToEle) {
                    setTimeout(() => {
                      const {top} = scrollToEle.getBoundingClientRect()
                      if (top < fullHeaderHeight) {
                        window.scrollTo({
                          top: top + window.scrollY - fullHeaderHeight,
                        })
                      }
                    })
                  }
                }
              }}
            />
          </article>
        </PageWithNav>
      </NavigationProvider>
    </VersionProvider>
  )
}
